import  React, {Component} from 'react';
import { GoogleMap, useLoadScript, Marker, Polyline, LoadScript } from '@react-google-maps/api';
import { Alert, AlertTitle } from '@mui/material';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import { Stack } from '@mui/material';
import './App.css'; 
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import * as signalR from "@microsoft/signalr";

//const url = "https://localhost:7231/api";
const url = "http://www.eiruv.online/api";
const libraries = ['places'];
const mapContainerStyle = {
  width: '100vw',
  height: '100vh',
   // Adjust for any header or footer height
};
const center = {
  lat: 31.80266, 
  lng: 35.21503 
};
const image = 'images/street-lamp-icon.png'
const App = () => {
  return (
    <div>
      <Main />
    </div>
  );
};

class Main extends React.Component {
  center = {
    lat: 31.80266,
    lng: 35.21503
  };
  state = { 
    locations: [],
    groups: [],
    selectedArea: "",
    connection: null,
    map: null,
    alerts: []
  };
  
  constructor(props) {
    super(props);
    this.state = {
      grid: [],
      groups: [],
      selectedArea: "",
      selectedAreaPoles: {},
      selectedAreaLines: {},
      connection: null,
      showAlert: false,
      alertContent: "",
      alerts: []
    }
  }

  componentDidMount() {
   
    this.queryGroups();
    const connection = new signalR.HubConnectionBuilder()
    .withUrl(`${url}/poleMessageHub`) // Your backend URL
    .withAutomaticReconnect()
    .build();

  connection.start()
    .then(() => console.log("SignalR Connected"))
    .catch(err => console.error("Connection failed: ", err));

 connection.on("alert", (data)=>{
  let alertContent = "";
  if (data.type  === 1){
    alertContent = `עמוד בהטייה ${data.degrees} מעלות, מזהה עמוד ${data.id}`
  } else {
    alertContent = `חוט קרוע , מזהה עמוד ${data.id}`
  }

  const newAlert = { id: Date.now(), content: alertContent };

  this.setState(prevState => ({
    alerts: [...prevState.alerts, newAlert]
  }), () => {
    setTimeout(() => {
      this.removeAlert(newAlert.id);
    }, 15000);
  });
 });
  connection.on("poleChanged", (data) => {
    this.queryGroups();
     
    if (this.state.selectedAreaPoles) {
      const updatedPoles = this.state.selectedAreaPoles.map(pole => {
        if (pole.id === data.id) {
          return { ...pole, degrees: data.degrees, poleStatus: data.poleStatus, lineStatus: data.lineStatus }; // Create a new object with updated values
        }
        return pole; // Return the original pole if no changes are made
      });
      this.setState({ selectedAreaPoles: updatedPoles });

      if (this.state.map) {
        this.refreshSinglePole(data.id, this.state.map)
      }
      if (this.polylines[data.id]) {
        this.polylines[data.id].setOptions({ strokeColor: data.lineStatus === 1 ? "#0000FF" : "red" });
      }
    }

    if (this.state.selectedArea) {
      fetch(`${url}/Grid/poles/${this.state.selectedArea}`, {
        method: 'GET',
        mode: 'no-cors'
      })
        .then((response) => response.json())
        .then((data) => { 
          this.refreshLines(data, this.state.map); 
        })
      .catch((error) => {
        // Handle the error
      });
    }
  });

  this.setState({ connection });

  }

  queryGroups() {
    fetch(`${url}/Grid/groups/binyamin`, {
      method: 'GET',
      mode: 'no-cors'
    })
    .then(response => response.json()) // Convert response to JSON
    .then(data => {
      console.log(data)
      this.setState({ groups: data });
    })
    .catch(error => {
    });
  }

  componentWillUnmount() {
    if (this.state.connection) {
      this.state.connection.stop(); // Stop the SignalR connection
    }
  }

  markers = {}
  polylines = {}
createMarkerAndInfoWindow(pole, map) {
  const { id, name, lat, lng, poleStatus, lineStatus, degrees } = pole;

  // Create marker
  const marker = new window.google.maps.Marker({
    id: id,
    position: { lat, lng },
    title: degrees.toString(),
    map: map,
    icon: {
      path: window.google.maps.SymbolPath.CIRCLE,
      fillColor: poleStatus === 1 ? "blue" : "red",
      fillOpacity: poleStatus === 1 ? 0.6 : 1,
      strokeWeight: 0,
      rotation: 0,
      scale: poleStatus === 1 ? 6 : 10,
    }
  });

  // Create InfoWindow
  const infoWindow = new window.google.maps.InfoWindow({
    content: `<div style="font-size: 20px; font-weight: bold; display: grid; grid-template-columns: 2fr 1fr; gap: 10px; direction: rtl;">
      <div>מזהה עמוד:</div>
      <div>${name}</div>
      <div>זווית הטייה:</div>
      <div>${degrees}</div>
      <div>מצב חוט:</div>
      <div>${lineStatus == 1 ? "תקין" : "קרוע"}</div>
    </div>`,
  });

  // Add listeners to show and hide the InfoWindow
  marker.addListener("mouseover", () => {
    infoWindow.open(map, marker);
  });

  marker.addListener("mouseout", () => {
    infoWindow.close();
  });

  return marker;
}

// Refresh all poles
refreshPoles(map) {
  // Remove existing markers
  Object.values(this.markers).forEach(marker => marker.setMap(null));
  this.markers = {};

  // Create new markers
  this.state.selectedAreaPoles.forEach(pole => {
    const marker = this.createMarkerAndInfoWindow(pole, map);
    this.markers[pole.id] = marker;
  });
}

// Refresh a single pole
refreshSinglePole(id, map) {
  // Remove existing marker if it exists
  if (this.markers[id]) {
    this.markers[id].setMap(null);
  }
  // Find and create a new marker for the specific pole
  const pole = this.state.selectedAreaPoles.find(pole => pole.id === id);
  if (pole) {
    const marker = this.createMarkerAndInfoWindow(pole, map);
    this.markers[id] = marker;
  } else {
    console.error(`Pole with id ${id} not found.`);
  }
}


refreshLines(lines, map) {
    Object.values(this.polylines).forEach(p => p.setMap(null));
    this.polylines = []
  
    lines.forEach((line) => {
      const polyline = new window.google.maps.Polyline({
        path: line.poles,
        geodesic: true,
        strokeColor: line.lineStatus === 1 ? "#0000FF" : "red",
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });
  
      polyline.setMap(map);
      this.polylines.push(polyline);
    })
  }
  fxx(grid) {
    const position =  {lat: 31.81911545700013, lng: 35.19921985568734};
    const map = new window.google.maps.Map(document.getElementById("map"), {
      zoom: 16.5,
      center: position,
    });
    this.setState({map: map})
    const poles =[];
    grid.map(group=> group.poles.map(p=> poles.push(p)))

    this.setState( { selectedAreaPoles: poles});
    this.poles = poles; 
    this.refreshPoles(map);
    
    this.refreshLines(grid, map);
    if (map == null)
    return
  const bounds = new window.google.maps.LatLngBounds();
  poles?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
  map?.fitBounds(bounds);
  }

  selectArea(selectedArea) {
    this.setState({ selectedArea: selectedArea });
    fetch(`${url}/Grid/poles/${selectedArea}`, {
      method: 'GET',
      mode: 'no-cors'
    })
    .then((response) => response.json())
    .then((data) => {
      this.setState({ grid: data });
      this.fxx(this.state.grid);
    })
    .catch((error) => {
      // Handle the error
    });
  }

  removeAlert(alertId) {
    this.setState(prevState => ({
      alerts: prevState.alerts.filter(alert => alert.id !== alertId)
    }));
  }

  render() {
    const { groups , selectedArea, alerts} = this.state;
     
    return (
      <div>
      <div style={{
        position: 'fixed',
        top: '20px',
        left: '20px',
        zIndex: 1000,
        direction: 'rtl'
      }}>
        <Stack spacing={2}>
          {alerts.map(alert => (
            <Alert key={alert.id} variant="filled" severity="error">
               <span style={{ marginRight: '8px' }}></span>
              {alert.content}
            </Alert>
          ))}
        </Stack>
      </div>
        <Grid container spacing={2} className="map-container">
          <Grid item xs={12} md={8} lg={10}>
            <LoadScript googleMapsApiKey="AIzaSyB2L90ZIZqo-Mgzu2kUknXQx0grrdS4zcM" language="he">
              <GoogleMap 
                id='map' 
                className="map-container"
                mapContainerStyle={mapContainerStyle}
                zoom={16.5}
                center={center}
              />
            </LoadScript>
          </Grid>
          <Grid item xs={12} md={4} lg={2}>
            <div className="grid-container">
              {groups.map((group, index) => (
                <div key={index}>
                  <Button
                    className="area-button"
                    variant="outlined"
                    sx={{ border: this.state.selectedArea == group.id ? '5px solid green !important' : '3.5px black solid !important'
                    }}
                    onClick={() => {
                      this.selectArea(group.id)
                    }}>
                    <div className="group-id-container">
                      {group.id}
                    </div>
                    <div className="warning-container">
                      {group.warnings > 0 && (
                        <div className="warning-content">
                          <WarningAmberIcon className="warning-icon" sx={{ color: 'red' }} />
                          ({group.warnings})
                        </div>
                      )}
                    </div>

                    
                  </Button>
                </div>
              ))}
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default App;